<div class="sector-container">
    <div class="row g-3">
        <div class="col-md-6">
            <div class="column card sector-public" (click)="onSectorSelect('public')">
                <img class="btn-img mb-3" src="/assets/icons/sectorul_public.svg" alt="Selectati sectorul public" />
                <p class="title">Sectorul public</p>
                <p class="subtitle">Soluții e-Guvernare</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="column card sector-privat" (click)="onSectorSelect('privat')">
                <img class="btn-img mb-3" src="/assets/icons/sectorul_privat.svg" alt="Selectati sectorul privat" />
                <p class="title">Sectorul privat</p>
                <p class="subtitle">Soluții e-Business</p>
            </div>
        </div>
    </div>
</div>