import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormContainerService } from '../../services/form-container.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-sector-selector',
    templateUrl: './sector-selector.component.html',
    styleUrls: ['./sector-selector.component.scss']
})
export class SectorSelectorComponent {
    @Input() form: FormGroup = new FormGroup({});

    constructor(
        private service: FormContainerService,
        private gtmService: GoogleTagManagerService
    ) { }

    onSectorSelect(sector: 'privat' | 'public') {
        // push GTM data layer with a custom event
        const gtmTag = {
            event: 'button-click',
            data: 'Sector Choice: ' + sector,
        };
        this.gtmService.pushTag(gtmTag);

        // alert('this is a custom event');

        this.service.setSector(sector);

    }
}
