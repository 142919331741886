<div class="header-container">
    <div class="header-inner" *ngIf="service.isSectorPublic()">
        <div class="header-img-container">
            <img class="btn-img mb-3" src="/assets/icons/e-guvernare.svg" alt="e-Guvernare" />
        </div>
        <div class="header-title">Soluții e-Guvernare</div>
    </div>

    <div class="header-inner" *ngIf="service.isSectorPrivat()">
        <div class="header-img-container">
            <img class="btn-img mb-3" src="/assets/icons/e-business.svg" alt="e-Business" />

        </div>
        <div class="header-title">Soluții e-Business</div>
    </div>
</div>

<div class="form-container p-5">


    <form [formGroup]="form">
        <h2>Date contact</h2>
        <div class="row">
            <mat-form-field class="col-md-6">
                <mat-label>Nume</mat-label>
                <input matInput formControlName="nume">
                <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('required')">
                    Câmp obligatoriu
                </mat-error>
                <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('minlength')">
                    Minim 3 caractere
                </mat-error>
                <mat-error *ngIf="nume && (nume.dirty || nume.touched) && nume.hasError('maxlength')">
                    Maxim 50 caractere
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-md-6">
                <mat-label>Prenume</mat-label>
                <input matInput formControlName="prenume">
                <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('required')">
                    Câmp obligatoriu
                </mat-error>
                <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('minlength')">
                    Minim 3 caractere
                </mat-error>
                <mat-error *ngIf="prenume && (prenume.dirty || prenume.touched) && prenume.hasError('maxlength')">
                    Maxim 50 caractere
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="col-12">
            <mat-label>Telefon contact</mat-label>
            <input matInput formControlName="telefon" placeholder="07** *** ***">
            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('required')">
                Câmp obligatoriu
            </mat-error>
            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('minlength')">
                Minim 3 caractere
            </mat-error>
            <mat-error *ngIf="telefon && (telefon.dirty || telefon.touched) && telefon.hasError('maxlength')">
                Maxim 15 caractere
            </mat-error>
        </mat-form-field>

        <mat-form-field class="col-12">
            <mat-label>Adresă email</mat-label>
            <input matInput formControlName="email" placeholder="test@gmail.com">
            <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('required')">
                Câmp obligatoriu
            </mat-error>
            <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('minlength')">
                Minim 3 caractere
            </mat-error>
            <mat-error *ngIf="email && (email.dirty || email.touched) && email.hasError('maxlength')">
                Maxim 100 caractere
            </mat-error>

            <mat-error *ngIf="email && (email.dirty || email.touched) && (email.hasError('email') || email.hasError('pattern'))">
                Email invalid
            </mat-error>
        </mat-form-field>

        <!-- <div > -->
            <mat-form-field class="col-12">
                <mat-label *ngIf="service.isSectorPublic()">Instituție</mat-label>
                <mat-label *ngIf="service.isSectorPrivat()">Companie</mat-label>
                <input matInput formControlName="institutie" [placeholder]="service.returnCorrectPlaceholderCompanyInstitution()">
                <mat-error *ngIf="institutie && (institutie.dirty || institutie.touched) && institutie.hasError('required')">
                    Câmp obligatoriu
                </mat-error>
                <mat-error *ngIf="institutie && (institutie.dirty || institutie.touched) && institutie.hasError('minlength')">
                    Minim 3 caractere
                </mat-error>
                <mat-error *ngIf="institutie && (institutie.dirty || institutie.touched) && institutie.hasError('maxlength')">
                    Maxim 100 caractere
                </mat-error>
            </mat-form-field>
        <!-- </div> -->

        <!-- <div >
            <mat-form-field class="col-12">
                <mat-label>Companie</mat-label>
                <input matInput formControlName="companie" placeholder="Numele companiei">
            </mat-form-field>
        </div> -->

        <mat-form-field class="col-12">
            <mat-label>Funcția ocupată</mat-label>
            <input matInput formControlName="functie" placeholder="Functie">
            <mat-error *ngIf="functie && (functie.dirty || functie.touched) && functie.hasError('required')">
                Câmp obligatoriu
            </mat-error>
            <mat-error *ngIf="functie && (functie.dirty || functie.touched) && functie.hasError('minlength')">
                Minim 3 caractere
            </mat-error>
            <mat-error *ngIf="functie && (functie.dirty || functie.touched) && functie.hasError('maxlength')">
                Maxim 50 caractere
            </mat-error>
        </mat-form-field>
    </form>


</div>