<div class="header-container">
    <div class="header-inner" *ngIf="service.isSectorPublic()">
        <div class="header-img-container">
            <img class="btn-img mb-3" src="/assets/icons/e-guvernare.svg" alt="e-Guvernare" />
        </div>
        <div class="header-title">Soluții e-Guvernare</div>
    </div>

    <div class="header-inner" *ngIf="service.isSectorPrivat()">
        <div class="header-img-container">
            <img class="btn-img mb-3" src="/assets/icons/e-business.svg" alt="e-Business" />

        </div>
        <div class="header-title">Soluții e-Business</div>
    </div>
</div>

<div class="form-container p-5">




    <div class="card-container p-0 m-0">
        <div class="card" *ngFor="let solution of solutions; let i=index" [class.selected]="isSelected(solution)" (click)="toggleSelection(solution)">
            <img class="btn-img mb-3" [src]="'/assets/icons/'+solution.name+'.svg'" [alt]="solution.name" />
            <p>
                {{solution.description}}
            </p>
        </div>


        <div class="column card card-special w-100">
            <h2>Soluție personalizată</h2>
            <p>Dezvoltare soluție nouă adaptată nevoilor dumneavoastră</p>
            <form [formGroup]="form" class="mt-3">
                <mat-label>Introduceți aici alte soluții adaptate nevoilor dumneavoastră</mat-label>
                <div class="row">
                    <mat-form-field class="col-12">
                        <mat-label>Alte soluții</mat-label>
                        <textarea matInput formControlName="altele" matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>

</div>