<div class="container mb-0">
    <nav class="navbar navbar-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="https://inovaredigitala.ro/wp-content/uploads/2024/02/Logo_inovareDigitala.png"
                    alt="Inovare Digitala" width="260" height="50" class="d-inline-block align-text-top">
            </a>
        </div>
    </nav>
</div>

<div class="container mb-0">
    <!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
        {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
    </button> -->

    <mat-stepper [linear]="isLinear" #stepper class="m-5">

        <mat-step [stepControl]="sectorSelectorForm">
            <ng-template matStepLabel>Alegeți tipul de activitate economică</ng-template>
            <app-sector-selector [form]="sectorSelectorForm"></app-sector-selector>
        </mat-step>

        <mat-step [stepControl]="personalDataForm" label="Introduceți datele dumneavoastră">
            <app-personal-data [form]="personalDataForm"></app-personal-data>
            <div class="row justify-content-between gap-2 ms-0 me-0">
                <button class="action-btn back col-6" mat-button matStepperPrevious>Înapoi</button>
                <button class="action-btn next col-6" mat-button matStepperNext>Pasul următor</button>
            </div>
        </mat-step>

        <mat-step [stepControl]="solutionsForm">
            <ng-template matStepLabel>Selectați soluțiile dorite</ng-template>
            <app-solutions [form]="solutionsForm"></app-solutions>
            <div class="row justify-content-between gap-2 ms-0 me-0">
                <button class="action-btn back col-6" mat-button matStepperPrevious>Înapoi</button>
                <button class="action-btn next col-6" mat-button (click)="onSend()"
                    [disabled]="loading">Trimiteți</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Finalizare</ng-template>
            <app-final-step></app-final-step>
            <div class="row justify-content-center gap-2 ms-0 me-0">
                <button class="w-auto" mat-button matStepperNext (click)="onReset()">Resetează</button>
            </div>
        </mat-step>
    </mat-stepper>

</div>
<footer class="footer mt-auto py-5">
    <div class="container">
        <div class="row d-flex flex-row justify-content-center align-items-center mt-5">
            <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-start px-5">
                <div class="row">
                    <div class="col">
                        <img class="img-with-animation skip-lazy nectar-lazy loaded" data-delay="0" height="58"
                            width="300" data-animation="none"
                            src="https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-300x58.png"
                            alt="" sizes="(max-width: 300px) 100vw, 300px" style=""
                            srcset="https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-300x58.png 300w, https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-1024x197.png 1024w, https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-768x148.png 768w, https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-1536x296.png 1536w, https://inovaredigitala.ro/wp-content/uploads/2024/03/inovareDigitala-logo-onDark@4x-2048x395.png 2048w">
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col d-flex flex-row justify-content-start align-items-center">
                        <h2>Suită Software
                            pentru DIGITALIZAREA &amp; TRANSFORMAREA DIGITALĂ a comunităților
                            locale</h2>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-sm-12 d-flex flex-column justify-content-between align-items-start">
                <div class="row d-flex flex-row justify-content-center align-items-start">
                    <div class="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-start">
                        <div class="row">
                            <div class="col">
                                <h5>inovareDigitală</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                <h4><span class="link_wrap"><a class="link_text" role="button" href="https://inovaredigitala.ro/#intro">Concept</a></span></h4>
                                <h4> <span class="link_wrap"><a class="link_text" role="button" href="https://inovaredigitala.ro/#SuitaSoftware">Soluții Digitale</a></span></h4>
                                <h4> <span class="link_wrap"><a class="link_text" role="button" href="https://inovaredigitala.ro/#SpatiulDigitalUnic">Spațiul Digital Unic</a></span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <h5>Contact</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h4> <span class="link_wrap"><a class="link_text" role="button" href="tel:+40774081824">+40 774 081 824</a></span></h4>
                                <h4> <span class="link_wrap"><a class="link_text" role="button" href="mailto:start@inovaredigitala.ro">start@inovaredigitala.ro</a></span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex flex-row justify-content-center align-items-center mb-5">
            <div class="row">
                <div class="col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-start px-5">

                </div>
                <div class="col-md-5 col-sm-12 d-flex flex-column justify-content-between align-items-start">
                    <p class="text-align">©&nbsp; <span class="nectar-current-year">2024</span> Inovare Digitală – Toate drepturile rezervate</p>
                </div>
            </div>
        </div>
    </div>
</footer>