import { Component, OnInit, Input } from '@angular/core';
import { PUBLIC_SOLUTIONS, PRIVATE_SOLUTIONS } from '../../models/constants';
import { FormContainerService } from '../../services/form-container.service';
import { SolutionModel } from '../../models/solution.model';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent {
    @Input() form: FormGroup = new FormGroup({});

    solutions: SolutionModel[] = [];
    selectedSolutions: SolutionModel[] = [];

    constructor(public service: FormContainerService, private stepper: MatStepper, private fb: FormBuilder, private gtmService: GoogleTagManagerService) {
        this.service.getSector$()
            .subscribe((sector: any) => {
                if (!sector) return;

                this.initSolutionsComponent();
            });

        this.service.getDataSubmitted$()
            .subscribe((isDataSubmitted: boolean) => {
                if (!isDataSubmitted) return;

                this.solutions = [];
                this.selectedSolutions = [];
            });
    }

    private initSolutionsComponent() {
        const sector = this.service.getSector();
        this.solutions = sector == 'privat' ? PUBLIC_SOLUTIONS : sector == 'public' ? PUBLIC_SOLUTIONS : [];

        // push GTM data layer with a custom event
        const gtmTag = {
            event: 'button-click',
            data: 'Personal data added for sector: ' + sector,
        };
        this.gtmService.pushTag(gtmTag);
    }

    toggleSelection(solution: SolutionModel): void {
        const index = this.selectedSolutions.indexOf(solution);
        if (index === -1) {
            this.selectedSolutions.push(solution);
            this.addSolutionToFormArray(solution);
        } else {
            this.selectedSolutions.splice(index, 1);
            this.removeSolutionFromFormArray(solution);
        }
    }

    isSelected(solution: SolutionModel): boolean {
        return this.selectedSolutions.includes(solution);
    }

    addSolutionToFormArray(solution: SolutionModel): void {
        const selectedSolutionsFormArray = this.form.get('solutions') as FormArray;
        selectedSolutionsFormArray.push(this.fb.control(solution));
    }

    removeSolutionFromFormArray(solution: SolutionModel): void {
        const selectedSolutionsFormArray = this.form.get('solutions') as FormArray;
        const index = selectedSolutionsFormArray.value.findIndex((s: SolutionModel) => s === solution);
        selectedSolutionsFormArray.removeAt(index);
    }
}
