// notification.service.ts

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	errorTitle: string = environment.config.customNotifications.headers.error;
	errorIcon: string = environment.config.customNotifications.icons.error;

	errorMessage = environment.config.customNotifications.generalMessages.error;

	constructor(private snackBar: MatSnackBar) { }

	showNotification(message: string, success: boolean): void {
		this.snackBar.open(message, 'Închide', {
			duration: 5000, // Duration in milliseconds
			panelClass: success ? ['success-notification'] : ['error-notification']
		});
	}

	handleHttpError(res: any) {
		if (res.errors && res.errors.message && res.errors.message.length > 0) {
			res.errors.message.forEach((msg: string) => {
				this.showNotification("Success", false);
			});
		} else {
			
			this.showNotification(this.errorMessage, false);
		}
	}
}