import { Component, Input } from '@angular/core';
import { FormContainerService } from '../../services/form-container.service';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'app-personal-data',
	templateUrl: './personal-data.component.html',
	styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent {
	@Input() form: FormGroup = new FormGroup({});

	selectedSector: string = '';
	constructor(
		public service: FormContainerService) {
	}

	get nume() {
		return this.form.get('nume');
	}

	get prenume() {
		return this.form.get('prenume');
	}

	get email() {
		return this.form.get('email');
	}

	get telefon() {
		return this.form.get('telefon');
	}

	get functie() {
		return this.form.get('functie');
	}

	get institutie() {
		return this.form.get('institutie');
	}
}
