export const environment = {
    production: true,
    basePath: 'https://start-api.inovaredigitala.ro',
    form: {
        contact: '/contact'
    },
    config: {
        customNotifications: {
            icons: {
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning',
            },
            headers: {
                success: 'Felicitări!',
                error: 'Atenție',
                successForm: 'Felicitări!',
            },
            generalMessages: {
                error:
                    'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport IT.',
                fileErrorMsg:
                    'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            },
        }
    }
};
