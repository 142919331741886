import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SectorSelectorComponent } from './components/sector-selector/sector-selector.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { SolutionsComponent } from './components/solutions/solutions.component';
import { FinalStepComponent } from './components/final-step/final-step.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';


@NgModule({
    declarations: [
        AppComponent,
        FinalStepComponent,
        FormContainerComponent,
        PersonalDataComponent,
        SectorSelectorComponent,
        SolutionsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule, MaterialModule,
        MatSnackBarModule,
        GoogleTagManagerModule.forRoot({
            id: "G-V6KN89F959",
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
