import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FormContainerService {
    masterForm: null | FormGroup<any> = null;
    private sectorSubject = new BehaviorSubject<string>('');
    private dataSubmittedSubject = new BehaviorSubject<boolean>(false);

    private baseUrl = environment.basePath;
    private contactUrl = this.baseUrl + environment.form.contact;

    constructor(private http: HttpClient, private fb: FormBuilder,
    ) { }

    setMasterForm(formGroups: { [key: string]: FormGroup }) {
        this.masterForm = this.fb.group(formGroups);
    }

    getSector(): '' | 'privat' | 'public' {
        const formSector = this.masterForm ?.get('sectorSelectorForm') as FormGroup;
        return formSector ?.controls['sector'] ?.value;
    }

    getSector$() {
        return this.sectorSubject.asObservable();
    }

    setSector(sector: string) {
        const formSector = this.masterForm ?.get('sectorSelectorForm') as FormGroup;
        formSector.patchValue({ sector: sector });

        this.sectorSubject.next(sector);
    }

    getDataSubmitted$() {
        return this.dataSubmittedSubject.asObservable();
    }

    setDataSubmitted(isSubmitted: boolean) {
        this.dataSubmittedSubject.next(isSubmitted);
    }

    isSectorPublic() {
        return this.getSector() == 'public';
    }

    isSectorPrivat() {
        return this.getSector() == 'privat';
    }

    returnCorrectPlaceholderCompanyInstitution() {
        return this.isSectorPublic() ? 'Numele Instituției' : 'Numele Companiei';
    }

    submitData() {
        console.log(this.masterForm ?.value);

        return this.http.post(this.contactUrl, this.masterForm ?.value)
            // .subscribe((response) => {
            //     console.log(response);
            // });

        // setTimeout(() => {
        //     this.setDataSubmitted(true);
        // }, 2000);

    }
}
