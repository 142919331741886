import { SolutionModel } from './solution.model';

// no need for icon in json as we are using the 'name' as the assets/icons/'name'.svg
export const PUBLIC_SOLUTIONS: SolutionModel[] = [
    {
        name: "inDoc",
        description: "Registratură ONLINE & platformă integrată de management al documentelor electronice"
    },
    {
        name: "inMyCity",
        description: "Smart App pentru comunitatea locală"
    },
    {
        name: "inPark",
        description: "Prima platformă de management integrat și exclusiv digital al parcărilor rezidențiale și publice"
    },
    {
        name: "inGateway",
        description: "Servicii publice digitalizate complet, integrate intr-un singur ecosistem. Comunitatea locală devine parte a SOLUȚIEI de Smart City"
    },
    {
        name: "inTax",
        description: "Platformă ONLINE pentru declararea și scoatere din evidență a bunurilor, eliberarea de certificate fiscale electronice"
    },
    {
        name: "inAgree",
        description: "Platformă ONLINE de autorizări comerciale și management al documentelor și fluxurilor interne"
    },
    {
        name: "inUrbanism",
        description: "Platformă pentru eliberarea de CU/AC online și managementul documentelor și fluxurilor interne"
    },
    {
        name: "inSmartDate",
        description: "Programări ONLINE pentru starea civilă și evidența persoanelor"
    },
    {
        name: "inMarket",
        description: "Managementul și gestiunea proceselor și fluxurilor din piețele agroalimentare publice"
    },

    {
        name: "inEnforcement",
        description: "Platformă digitală pentru managementul integrat al activităților de sancționare a parcării neregulamentare. Ridicări auto & Blocarea Roților"
    },
    {
        name: "inStock",
        description: "Platformă pentru gestiunea bunurilor de patrimoniu și a fluxurilor administrative"
    },
    {
        name: "inStreet",
        description: "Inventarierea și gestiunea elementelor de semnalizare rutieră, mobilier urban și a bunurilor de patrimoniu"
    }
]

export const PRIVATE_SOLUTIONS: SolutionModel[] = [
    {
        name: "inPark",
        description: "Prima platformă de management integrat și exclusiv digital al parcărilor rezidențiale și publice"
    },
    {
        name: "inMarket",
        description: "Managementul și gestiunea proceselor și fluxurilor din piețele agroalimentare publice"
    },
    {
        name: "inDoc",
        description: "Registratură ONLINE & platformă integrată de management al documentelor electronice"
    },
    {
        name: "inStock",
        description: "Platformă pentru gestiunea bunurilor de patrimoniu și a fluxurilor administrative"
    },
    {
        name: "inAgree",
        description: "Platformă ONLINE de autorizări comerciale și management al documentelor și fluxurilor interne"
    },
    {
        name: "inSmartDate",
        description: "Programări ONLINE pentru starea civilă și evidența persoanelor"
    }
]

