import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormArray } from '@angular/forms';
import { FormContainerService } from '../../services/form-container.service';
import { MatStepper } from '@angular/material/stepper';

import { NotificationService } from 'src/app/services/notification.service';
import { finalize } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-form-container',
    templateUrl: './form-container.component.html',
    styleUrls: ['./form-container.component.scss']
})
export class FormContainerComponent implements OnInit {
    @ViewChild('stepper') stepper!: MatStepper;
    isLinear = true;

    sectorSelectorForm = this.fb.group({
        sector: ['', [Validators.required]]
    });

    loading: boolean = false;

    emailRegex: string = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';

    personalDataForm = this.fb.group({
        nume: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
        prenume: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
        telefon: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(15)])],
        email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern(this.emailRegex), Validators.maxLength(100)])],
        institutie: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
        // companie: ['',],
        functie: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
    });

    solutionsForm = this.fb.group({
        solutions: this.fb.array([]),
        altele: ['']
    });

    constructor(private fb: FormBuilder, public service: FormContainerService, private notificationService: NotificationService, private gtmService: GoogleTagManagerService) { }

    ngOnInit(): void {
        this.service.setMasterForm({
            sectorSelectorForm: this.sectorSelectorForm,
            personalDataForm: this.personalDataForm,
            solutionsForm: this.solutionsForm
        })

        this.service.getSector$()
            .subscribe((sector: any) => {
                if (!sector) return;

                this.stepper.next();
            });

        this.service.getDataSubmitted$()
            .subscribe((isDataSubmitted: boolean) => {
                if (!isDataSubmitted) return;

                this.stepper.next();
            });
    }

    onSend() {
        if(this.loading) return;
        
        this.loading = true;

        // push GTM data layer with a custom event
        const gtmTag = {
            event: 'order-submit',
            data: 'Form submitted successfully',
        };
        this.gtmService.pushTag(gtmTag);

        this.service.submitData()
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (res: any) => {
                    console.log(res, 'success');

                    if(res.status_code == 200) {
                        this.service.setDataSubmitted(true);
                        this.notificationService.showNotification('Solicitare transmisă cu succes', true);
                    }else {
                        // console.log(res.message)
                        this.notificationService.handleHttpError(res);
                    }
                },
                error: (res: any) => {
                    // console.log(res, 'success');
                    this.notificationService.handleHttpError(res);
                }
            });
    }

    onReset() {
        this.sectorSelectorForm.reset();
        this.personalDataForm.reset();
        this.solutionsForm.reset();
        const solutionsArray = this.solutionsForm.get('solutions') as FormArray;
        solutionsArray.clear();

        this.stepper.reset()
    }
}
