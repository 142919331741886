import { Component } from '@angular/core';
import { FormContainerService } from '../../services/form-container.service';

@Component({
    selector: 'app-final-step',
    templateUrl: './final-step.component.html',
    styleUrls: ['./final-step.component.scss']
})
export class FinalStepComponent {
}
